import findLast from 'lodash/findLast';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { datadogLogs } from '@datadog/browser-logs';
import { AxiosError } from 'axios';
import { Nullable } from '@global/types';
import {
    getComment,
    getCommentDecision,
    NoPolicyError,
    postEvaluatePolicy,
    postSubmitByCommentId,
    resetComment,
    tagComment,
    untagComment,
    updateComment,
} from '@tymely/api';
import {
    commentIdToFocusOnAtom,
    historicAnalysis,
    IComment,
    IDecision,
    IDineshTicketOperations,
    IPolicySet,
    isPolicyEvaluated,
    isPolicyEvaluationRunning,
    submittingComments,
    ticketAtom,
    TicketReportCategory,
} from '@tymely/atoms';

import { useIntentsQuery } from './intent.services';
import { useSetAlert } from './alerts.services';
import { useTicket } from './ticket.services';
import { useNotes, useTicketReport } from './note.services';
import { AppMode, useAppMode } from './mode';
import { useCreateHandlingDurationsCrumb } from './ticketTrail.services';
import { useArgumentsQuery } from './argument.services';
import { useFeatureFlags } from './feature.services';
import { useActionsMetadataQuery } from './action.services';

export const DECISION_QUERY_KEY = 'decision';
export const ARGUMENTS_QUERY_KEY = 'arguments';
export const AGENT_RESPONSE_QUERY_KEY = 'agentResponse';

export const useUpdateTicketComment = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback(
        (comment: IComment) => {
            setTicket(
                (ticket) =>
                    ticket && {
                        ...ticket,
                        comments: ticket.comments.map((it) => (it.id === comment.id ? comment : it)),
                    },
            );
        },
        [setTicket],
    );
};

const useUpdateComment = () => {
    const updateTicketComment = useUpdateTicketComment();
    return (comment: IComment) => {
        updateTicketComment(comment);
        return updateComment(comment.id, comment);
    };
};
export const useCommentApproveIntent = () => {
    const updateComment = useUpdateComment();
    return useCallback(
        (comment: IComment, approvedBy: IComment['agent_username']) => {
            const commentUpdate = {
                ...comment,
                additional_data: {
                    ...comment.additional_data,
                    is_intent_approved: true,
                },
            };

            if (approvedBy) {
                commentUpdate.agent_username = approvedBy;
            }

            return updateComment(commentUpdate);
        },
        [updateComment],
    );
};

export const useCommentToggleIsCustomer = () => {
    const updateComment = useUpdateComment();
    return useCallback(
        (comment: IComment) =>
            updateComment({
                ...comment,
                is_customer: !comment.is_customer,
            }),
        [updateComment],
    );
};

export const useCommentIdToFocusOn = () => useRecoilValue(commentIdToFocusOnAtom);

export const useSetCommentIdToFocusOn = () => {
    const setCommentIdToFocusOnAtom = useSetRecoilState(commentIdToFocusOnAtom);
    return useCallback((id?: number) => setCommentIdToFocusOnAtom(id), [setCommentIdToFocusOnAtom]);
};

export const useSelectedComment = () => useRecoilValue(selectedCommentSelector);

export const useHistoricAnalysis = () => useRecoilValue(historicAnalysis);
export const useSetHistoricAnalysis = () => {
    const seHistoricAnalysis = useSetRecoilState(historicAnalysis);
    return useCallback((historicAnalysis: IComment) => seHistoricAnalysis(historicAnalysis), [seHistoricAnalysis]);
};

export const useFindIntent = (intentId: Nullable<IPolicySet['id']> | undefined) => {
    const intents = useIntentsQuery();
    return intents.data?.find((intent) => intent.id === intentId);
};

export const useSelectedCommentIntents = () => {
    const intents = useIntentsQuery();
    const selectedComment = useRecoilValue(selectedCommentSelector);

    const intentIds = useMemo(() => {
        return new Set(
            [selectedComment?.selected_intent_id]
                .concat(selectedComment?.additional_data.multiple_intents)
                .filter(Boolean),
        );
    }, [selectedComment?.selected_intent_id, selectedComment?.additional_data.multiple_intents]);

    return useMemo(
        () =>
            Array.from(intentIds)
                .map((id) => intents.data?.find((intent) => intent.id === id))
                .filter(Boolean),
        [intents.data, intentIds],
    );
};

export const selectedCommentSelector = selector<Nullable<IComment>>({
    key: 'selectedCommentSelector',
    get: ({ get }) => {
        const comments = get(ticketAtom)?.comments;
        const commentIdToFocusOn = get(commentIdToFocusOnAtom);
        let comment = comments?.find(({ id, is_customer }) => id === commentIdToFocusOn && is_customer);
        if (!comment) {
            comment = findLast(comments, (comment) => comment.is_customer);
        }
        return comment ?? null;
    },
});

export const SPECIAL_WORDS = ['none', 'null', 'undefined', 'unspecified'];

export const highlightSpecialWords = (text: string, specialWords: string[] = SPECIAL_WORDS) => {
    return specialWords.reduce((text, word) => text.replace(new RegExp(`(${word})`, 'gi'), '<mark>$1</mark>'), text);
};

export const useAgentResponse = (lodable?: boolean) => {
    const ticket = useTicket();
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const updateTicketComment = useUpdateTicketComment();
    const { appMode = AppMode.Training } = useAppMode();
    const { saveNote } = useNotes();
    const queryClient = useQueryClient();
    const { data: decision } = useSelectedCommentDecisionQuery();
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();
    const [submitting] = useRecoilState<{ [key: IComment['id']]: boolean }>(submittingComments);
    const updateResponse = useCallback(
        (response: string) => {
            queryClient.setQueryData<string>(['agentResponse', commentId], response);
        },
        [commentId],
    );
    const { report } = useTicketReport();

    const query = useQuery(
        ['agentResponse', commentId],
        () =>
            commentId
                ? getComment(commentId).then((comment) => {
                      updateTicketComment(comment);
                      return comment.response_body;
                  })
                : Promise.reject('Comment is not selected'),
        {
            enabled: Boolean(selectedComment && lodable),
            initialData: selectedComment?.response_body,
            staleTime: Infinity,
        },
    );

    const sendResponse = useCallback(
        async (response: string) => {
            if (!selectedComment) return;
            createHandlingDurationsCrumb(IDineshTicketOperations.USER_SUBMITTED_TICKET);
            return postSubmitByCommentId(selectedComment.id, false, appMode, response);
        },
        [ticket?.id, appMode, selectedComment],
    );

    const updateComment = useUpdateComment();

    const addEscalation = useCallback(
        async (note: string, response: string, category: TicketReportCategory) => {
            if (!selectedComment) return;
            await updateComment({ ...selectedComment, response_body: response });
            await report(category, note, true);
        },
        [saveNote],
    );

    return {
        ...query,
        appMode,
        agentResponse: decision ? (decision.workflow_id ? query.data : '') : null,
        notSet: !decision?.workflow_id || !selectedComment?.response_body,
        sendResponse,
        updateResponse,
        addEscalation,
        isSubmitting: !isEmpty(submitting),
    };
};

export const useDecisionQuery = (commentId?: IComment['id'], policySetId?: number, enabled = true) => {
    const actionsMetadataQuery = useActionsMetadataQuery();

    return useQuery(
        [DECISION_QUERY_KEY, commentId],
        async () => {
            if (!commentId) {
                throw Error();
            }
            const decision = await getCommentDecision(commentId, policySetId);
            if (decision) {
                const action_executions = decision.action_executions.map((action_exec) => ({
                    ...action_exec,
                    action_metadata: actionsMetadataQuery.data!.filter(
                        (md) => md.id === action_exec.action_metadata_id,
                    )[0],
                }));
                return { ...decision, action_executions };
            }
            return decision;
        },
        {
            enabled: Boolean(enabled && commentId && actionsMetadataQuery.data),
            retry: false,
            staleTime: Infinity,
        },
    );
};

export const useSelectedCommentDecisionQuery = (enabled = true) => {
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const queryClient = useQueryClient();
    const decisionQuery = useDecisionQuery(commentId, selectedComment?.selected_intent_id ?? undefined, enabled);
    const updateDecision = (decision: IDecision | null) => {
        queryClient.setQueryData([DECISION_QUERY_KEY, commentId], decision);
    };

    return { ...decisionQuery, updateDecision };
};

export const useEvaluatePolicy = (opts?: { onSuccess?: () => void; onError: (error: AxiosError) => void }) => {
    const queryClient = useQueryClient();
    const setPolicyEvaluated = useSetRecoilState(isPolicyEvaluated);
    const setPolicyEvaluationRunning = useSetRecoilState(isPolicyEvaluationRunning);
    useFeatureFlags();

    return useMutation<IComment, AxiosError | NoPolicyError, { commentId: number; runAsync: boolean }>(
        (params: { commentId: number; runAsync: boolean }) => {
            setPolicyEvaluated(false);
            setPolicyEvaluationRunning(true);
            return postEvaluatePolicy(params.commentId, undefined, true);
        },
        {
            ...opts,
            onSuccess: (_, { commentId }) => {
                setPolicyEvaluated(true);
                return Promise.all([
                    queryClient.invalidateQueries([DECISION_QUERY_KEY, commentId]),
                    queryClient.invalidateQueries([ARGUMENTS_QUERY_KEY, commentId]),
                    queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, commentId]),
                ]).then(() => opts?.onSuccess?.());
            },
            onError() {
                setPolicyEvaluationRunning(false);
            },
            mutationKey: 'evaluatePolicy',
        },
    );
};

export const useTagComment = () => {
    const setAlert = useSetAlert();
    const updateTicketComment = useUpdateTicketComment();
    const queryClient = useQueryClient();
    const { appMode = AppMode.Training } = useAppMode();
    const { mutate: evaluatePolicy } = useEvaluatePolicy();

    const comment = useSelectedComment();
    useArgumentsQuery({
        commentId: comment?.id,
        enabled: !!comment?.selected_intent_id,
    });

    useFeatureFlags();

    return useCallback(
        async (commentId: number, intentIds?: number[]) => {
            try {
                let comment;
                if (intentIds) {
                    comment = await tagComment(commentId, intentIds, appMode);
                    evaluatePolicy({ commentId, runAsync: true });
                } else {
                    comment = await untagComment(commentId);
                }

                updateTicketComment(comment);

                await Promise.all([
                    queryClient.invalidateQueries([DECISION_QUERY_KEY, commentId]),
                    queryClient.invalidateQueries([ARGUMENTS_QUERY_KEY, commentId]),
                    queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, commentId]),
                ]);
            } catch (error) {
                if (intentIds) {
                    datadogLogs.logger.error(`Failed tagging ${intentIds.join(', ')}`);
                    setAlert((error as Error).message, 'error', 5000, 'Tagging has failed');
                }
            }
        },
        [appMode, queryClient, updateTicketComment],
    );
};

export const useResetComment = (comment?: IComment) => {
    const updateTicketComment = useUpdateTicketComment();
    const queryClient = useQueryClient();

    return useMutation(
        async () => {
            if (!comment) return;
            return resetComment(comment.id).then(() => {
                updateTicketComment({ ...comment, selected_intent_id: null });
                return comment;
            });
        },
        {
            onSuccess(comment) {
                if (!comment) return;
                return Promise.all([
                    queryClient.invalidateQueries([DECISION_QUERY_KEY, comment.id]),
                    queryClient.invalidateQueries([ARGUMENTS_QUERY_KEY, comment.id]),
                    queryClient.invalidateQueries([AGENT_RESPONSE_QUERY_KEY, comment.id]),
                ]);
            },
            mutationKey: ['resetComment', comment?.id],
        },
    );
};
