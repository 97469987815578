import React from 'react';
import {
    IArgument,
    IArgumentCategory,
    IArgumentDisplayRegulator,
    IArgumentString,
    IArgumentUpdate,
    isFalsy,
} from '@tymely/atoms';
import { Box, Button, FormHelperText, ListItem, styled } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogLogs } from '@datadog/browser-logs';
import ApprovedIcon from '@tymely/components/Icons/Approved';

import { ArgErrorFallback, Argument } from '../ArgumentTypes/Layout';
import { ArgCard } from './styled';
import ArgEditor, { ArgEditorProps } from './ArgEditor';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const MEDIA_ARGUMENT_DTYPES: IArgument['dtype'][] = [
    'Image',
    'Url',
    'VideoUrl',
    'list[Image]',
    'list[Url]',
    'list[VideoUrl]',
];

const _ListItem = styled(ListItem)`
    padding-left: 0;
    padding-right: 0;
`;

const _ApprovedIconContainer = styled(Box)`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const _ApproveContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

const ArgumentSeparator = styled('hr')`
    margin: ${({ theme }) => theme.spacing(0.5, -4)};
    border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
`;

type ArgumentListItemProps = {
    argument: IArgument;
    groupArgument?: IArgumentCategory;
    displayRegulatorArgument?: IArgumentDisplayRegulator;
    searchArgument?: IArgumentString;
    withCopy?: boolean;
    withApproval?: boolean;
    approved?: boolean;
    editable?: boolean;
    disabled?: boolean;
    loading?: boolean;
    withSeparator?: boolean;
    onUpdate?: (updates: IArgumentUpdate[], isApprove: boolean) => void;
    setHighlightText?: (text: string) => void;
};

const ArgumentListItem = React.memo(
    ({
        argument,
        groupArgument,
        displayRegulatorArgument,
        searchArgument,
        withCopy,
        withApproval,
        approved,
        editable,
        disabled,
        loading,
        withSeparator,
        onUpdate,
    }: ArgumentListItemProps) => {
        const { version, onHighlightText } = useArgumentsTabsContext();
        const [editing, setEditing] = React.useState(false);

        React.useEffect(() => {
            setEditing(Boolean(!approved && editable));
        }, [approved, editable]);

        const editorProps: ArgEditorProps = {
            argument,
            groupArgument,
            displayRegulatorArgument,
            searchArgument,
            loading,
            disabled: disabled || (withApproval && !editing),
            setHighlightText: !withApproval ? onHighlightText : undefined,
            onChange: async (args) => {
                const updates = args.map((arg) => ({
                    id: arg.id,
                    value: arg.value,
                }));
                onUpdate?.(updates, false);
                return args;
            },
        };

        const isApproveEnabled = !isFalsy(argument);

        const onApprove = React.useCallback(() => {
            datadogLogs.logger.info('onApprove');
            const updateArguments: IArgumentUpdate[] = [
                {
                    id: argument.id,
                    value: argument.value,
                    special_value: argument.is_unspecified ? 'unspecified' : undefined,
                },
            ];

            if (groupArgument) {
                updateArguments.push({
                    id: groupArgument.id,
                    value: groupArgument.value,
                    special_value: groupArgument.is_unspecified ? 'unspecified' : undefined,
                });
            }

            onUpdate?.(updateArguments, true);

            setEditing(false);
        }, [onUpdate, argument, groupArgument]);

        React.useLayoutEffect(() => {
            if (['string', 'number'].includes(typeof argument.value)) {
                onHighlightText?.(editing ? String(argument.value) : '');
            }
        }, [editing, argument.value, onHighlightText]);

        const isDTypeValid = MEDIA_ARGUMENT_DTYPES.includes(argument.dtype);

        const onSetUnspecified = React.useCallback(
            (id: number) => {
                if (version || (withApproval && !editing)) {
                    return;
                }
                const updates: IArgumentUpdate[] = [];
                if (!version) {
                    if (groupArgument) {
                        updates.push({
                            id: groupArgument.id,
                            value: null,
                            special_value: 'unspecified',
                        });
                    }
                    updates.push({
                        id,
                        value: null,
                        special_value: 'unspecified',
                    });
                }
                onUpdate?.(updates, false);
                setEditing(false);
            },
            [version, withApproval, editing, onUpdate, groupArgument],
        );

        const _onClick = () => {
            if (approved && editable && !editing) {
                setEditing(true);
            }
        };

        return (
            <>
                {withSeparator && <ArgumentSeparator />}
                <_ListItem onClick={_onClick} data-argument-name={argument.name}>
                    <ArgCard withApproval={withApproval} active={editable && !approved} approved={approved}>
                        <Box display="flex" flexDirection="row">
                            {isDTypeValid && (
                                <ErrorBoundary FallbackComponent={ArgErrorFallback}>
                                    <ArgEditor {...editorProps} />
                                </ErrorBoundary>
                            )}
                            {!isDTypeValid && (
                                <Argument
                                    argument={argument}
                                    className={argument.extractor_cls_name.toLowerCase()}
                                    withCopy={withCopy}
                                    disabled={disabled || loading || argument.arg_type === 'INFO_ARGUMENT'}
                                    mutable={!version}
                                    onSetUnspecified={onSetUnspecified}
                                >
                                    <_ApprovedIconContainer>
                                        <ArgEditor {...editorProps} />
                                        {!editing && approved && <ApprovedIcon />}
                                    </_ApprovedIconContainer>
                                </Argument>
                            )}
                        </Box>
                        {editing && withApproval && (
                            <_ApproveContainer>
                                {((withApproval && editing) || argument.show_description) && argument.description && (
                                    <FormHelperText sx={{ mr: 1, flex: 1, fontStyle: 'italic', ml: 2 }}>
                                        {argument.description}
                                    </FormHelperText>
                                )}
                                <Button
                                    disabled={!isApproveEnabled}
                                    color="primary"
                                    variant="contained"
                                    onClick={onApprove}
                                >
                                    Approve
                                </Button>
                            </_ApproveContainer>
                        )}
                    </ArgCard>
                </_ListItem>
            </>
        );
    },
);

ArgumentListItem.displayName = 'ArgumentListItem';

export default ArgumentListItem;
