import axios, { AxiosResponse } from 'axios';
import { IPolicySet } from '@tymely/atoms';

export const fetchIntents = () =>
    axios
        .get<{ results: IPolicySet[] }>('policy-sets/list', { params: { limit: 1000 } })
        .then((res) => res.data.results);

export const postNewIntent = (name: string, category: string, suggested: boolean) =>
    axios.post<IPolicySet>('policy-set', { name, category, suggested }).then((resp) => resp.data);

export const putIntent = (intent: { id: IPolicySet['id']; category: string }) =>
    axios
        .put<{ id: IPolicySet['id']; category: string }, AxiosResponse<IPolicySet>>(`policy-set/${intent.id}`, intent)
        .then((resp) => resp.data);

export const fetchDecisionLlmClusters = (orgId: number, policySetId: number) =>
    axios
        .get<{ results: [string, string][] }>(`organization/${orgId}/decision-llm-clusters`, {
            params: { policy_set_id: policySetId },
        })
        .then((res) => res.data);

export interface IReportInfo {
    report_id: string;
}

export const createTandemReport = (
    orgId: number,
    policySetIds: number[] | null,
    numberOfTickets: number | null,
    startDate: Date | null,
    endDate: Date | null,
    sendTo: string[],
    clusterWorkflows: boolean,
    scoreResponses: boolean,
    joinArgumentsData: boolean,
    onlyWithOriginalResponse: boolean,
    ticketIds?: number[] | null,
    clusteringPromptOverride?: string | null,
    forceEvaluateArgNames?: string[],
    initialLlmClusteringDescriptions?: [string, string][],
) =>
    axios.post<IReportInfo>(`reports/tandem/create?async=true`, {
        organization_id: orgId,
        policy_set_ids: policySetIds,
        tickets_number: numberOfTickets,
        start_date: startDate?.toISOString(),
        end_date: endDate?.toISOString(),
        send_to: sendTo,
        cluster_workflows: clusterWorkflows,
        score_responses: scoreResponses,
        join_arguments_data: joinArgumentsData,
        only_with_original_response: onlyWithOriginalResponse,
        ticket_ids: ticketIds,
        clustering_prompt_override: clusteringPromptOverride,
        force_evaluate_arg_names: forceEvaluateArgNames,
        initial_wf_descriptions: initialLlmClusteringDescriptions,
    });
