import { JSONSchema7 } from 'json-schema';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogLogs } from '@datadog/browser-logs';
import {
    createOrganization,
    editOrganization,
    fetchOrganization,
    fetchOrganizationSchema,
    fetchOrganizations,
    hasPolicy,
} from '@tymely/api';
import {
    IConsumptionChannel,
    IOrganization,
    IOrganizationCreate,
    IOrganizationUpdate,
    selectedChannelsAtom,
} from '@tymely/atoms';
import { AxiosError } from 'axios';
import uniqBy from 'lodash/uniqBy';

import { useSetAlert } from './alerts.services';

export const useOrganizationSchemaQuery = () =>
    useQuery<JSONSchema7>('organization-schema', () => fetchOrganizationSchema());

export const useOrganizationQuery = (orgId: IOrganization['id'], options?: UseQueryOptions<IOrganization>) => {
    let enabled = !Number.isNaN(orgId);
    if (typeof options?.enabled === 'boolean') {
        enabled = enabled && options.enabled;
    }
    return useQuery<IOrganization>(['organization', orgId], () => fetchOrganization(orgId), { enabled, ...options });
};

export const useEditOrganizationMutation = () => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IOrganization, AxiosError, IOrganizationUpdate>(editOrganization, {
        onSuccess: async (org) => {
            await Promise.all([
                queryClient.invalidateQueries(['organization', org.id]),
                queryClient.invalidateQueries(['organizations']),
            ]);
        },
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed editing organization.');
        },
    });
};

export const useCreateOrganizationMutation = () => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation<IOrganization, AxiosError, IOrganizationCreate>(createOrganization, {
        onSuccess: async () => {
            await Promise.all([queryClient.invalidateQueries(['organizations'])]);
        },
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed creating organization.');
        },
    });
};

export const useOrganizationsQuery = (liveOnly?: boolean, onSuccess?: UseQueryOptions<IOrganization[]>['onSuccess']) =>
    useQuery<IOrganization[]>(
        ['organizations'],
        () =>
            fetchOrganizations(liveOnly).then((orgs) => {
                return orgs
                    .sort((o1, o2) => {
                        if (o1.is_live_client === o2.is_live_client) return o1.name > o2.name ? 1 : -1;
                        if (o1.is_live_client && !o2.is_live_client) return -1;
                        return 1;
                    })
                    .filter((org) => (liveOnly ? org.is_live_client : true));
            }),
        { onSuccess },
    );

export const useSelectedOrganizations = () => {
    const selectedChannels = useSelectedChannels();
    return uniqBy(
        selectedChannels.map((channel) => channel.organization),
        (org) => org.id,
    );
};

export const useChannels = (): { channels: IConsumptionChannel[]; isLoading: boolean } => {
    const organizationsQuery = useOrganizationsQuery(false);

    if (!organizationsQuery.data) {
        return { channels: [], isLoading: organizationsQuery.isLoading };
    }

    const channels = organizationsQuery.data
        .map((org) => {
            const channels = org.config.consumption?.channels;

            if (channels === undefined) {
                return [];
            }

            return channels.map((channelConfig) => ({
                uiId: `${org.name}-${channelConfig.name}`,
                organization: org,
                ...channelConfig,
            }));
        })
        .flat();

    return {
        channels,
        isLoading: organizationsQuery.isLoading,
    };
};

export const useSetSelectedChannels = () => useSetRecoilState(selectedChannelsAtom);
export const useSelectedChannels = () => useRecoilValue(selectedChannelsAtom);

export const useHasPolicy = (orgPolicySetId: number, policySetId: number | null, options?: UseQueryOptions<boolean>) =>
    useQuery<boolean>(
        ['organizations', orgPolicySetId, policySetId],
        () => (policySetId ? hasPolicy(orgPolicySetId, policySetId) : Promise.resolve(false)),
        {
            enabled: Boolean(orgPolicySetId && policySetId),
            staleTime: Infinity,
            ...options,
            onSuccess: (hasPolicy) => {
                if (!hasPolicy) {
                    datadogLogs.logger.info(`Policy does not exist for intent ${policySetId}`);
                }
                options?.onSuccess?.(hasPolicy);
            },
        },
    );
