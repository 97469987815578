import { ITemplateVariable } from '@tymely/api';
import { IArgumentMetadata } from '@tymely/atoms';
import React from 'react';
import { useDebounce } from '@tymely/utils';
import { useArgumentsMetadataQuery, useTemplateFreeVariablesQuery } from '@tymely/services';
import { Box, styled, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Loader } from '@tymely/components';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Variable from './Variable';
import { useTemplateEditor } from './TemplateEditorProvider';

const _Root = styled(Box)`
    flex: 1;
    padding-top: ${({ theme }) => theme.spacing(1)};
    overflow: auto;
`;

const VariableList = () => {
    const { template, variables, setVariables, argsVariables } = useTemplateEditor();
    const argumentsMetadataQuery = useArgumentsMetadataQuery();
    const _template = useDebounce(template ?? '');
    const freeVariablesQuery = useTemplateFreeVariablesQuery(_template);

    const argMetadata = React.useMemo(() => {
        return argumentsMetadataQuery.data?.reduce<Record<string, IArgumentMetadata>>(
            (acc, item) => ({ ...acc, [item.name]: item }),
            {},
        );
    }, [argumentsMetadataQuery.data]);

    const metaVars = React.useMemo(() => {
        return freeVariablesQuery.data?.reduce<Record<string, ITemplateVariable>>(
            (acc, varName) => ({
                ...acc,
                [varName]: {
                    value: '',
                    dtype: argMetadata?.[varName]?.dtype ?? 'str',
                    arg_type: argMetadata?.[varName]?.arg_type ?? 'VARIABLE',
                    is_list: argMetadata?.[varName]?.is_list ?? false,
                    is_neither: argsVariables[varName]?.is_neither ?? false,
                    is_unspecified: argsVariables[varName]?.is_unspecified ?? false,
                    group_by: argsVariables[varName]?.group_by,
                },
            }),
            {},
        );
    }, [argMetadata, argsVariables, freeVariablesQuery.data]);

    const _variables = React.useMemo(() => {
        if (!metaVars) {
            return {};
        }
        return Object.keys(metaVars).reduce((vars, varName) => {
            vars[varName] = { ...metaVars[varName], ...argsVariables[varName] };
            return vars;
        }, metaVars);
    }, [variables, argsVariables, metaVars]);

    const _onVariableChange = (varName: string, variable: ITemplateVariable) => {
        const vars = {
            ..._variables,
            [varName]: { ..._variables[varName], ...variable },
        };
        setVariables(vars);
    };

    if (Object.keys(_variables).length === 0) {
        if (freeVariablesQuery.isError || argumentsMetadataQuery.isError) {
            return <Typography color="error">Error</Typography>;
        }

        if (freeVariablesQuery.isLoading || argumentsMetadataQuery.isLoading) {
            return <Typography>Loading...</Typography>;
        }

        return <Typography color="gray">No free variables detected in the template</Typography>;
    }

    if (!argMetadata) {
        return <Loader />;
    }

    return (
        <_Root>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {Object.keys(_variables)
                    .sort((v1, v2) => v1.localeCompare(v2))
                    .map((variableName) => (
                        <Variable
                            key={variableName}
                            name={variableName}
                            value={_variables[variableName]}
                            argMetadata={argMetadata[variableName]}
                            onChange={_onVariableChange}
                        />
                    ))}
            </LocalizationProvider>
        </_Root>
    );
};

export default VariableList;
