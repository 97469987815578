import { ITemplateVariable } from '@tymely/api';
import { IArgument, IArgumentMetadata } from '@tymely/atoms';

const convertToArgument = (argMetadata: IArgumentMetadata, variable: ITemplateVariable) => {
    const argMetaCategories =
        Object.keys(argMetadata.options?.categories || {}).length > 0 ? argMetadata.options?.categories : null;

    const argument = {
        id: argMetadata.id,
        md_id: argMetadata.id,
        name: argMetadata.name,
        extractor_cls_name: argMetadata.extractor_name,
        title: argMetadata.title,
        description: argMetadata.description,
        is_edited: true,
        is_unspecified: argMetadata.unspecifiable ? variable.is_unspecified : false,
        is_list: argMetadata.is_list,
        unspecifiable: argMetadata.unspecifiable,
        neitherable: argMetadata.options?.neitherable ?? true,
        dtype: argMetadata.dtype,
        arg_type: argMetadata.arg_type,
        order: 1,
        value: variable.value,
        categories: variable.categories || argMetaCategories,
        created_at: new Date().toISOString(),
    };

    return argument as IArgument;
};

export default convertToArgument;
